import allText from "@cms/tools.acerta.be.json";
import { PlatformText } from "@cms/types";
import LinkToExternalPage from "@components/navigation/helpers/LinkToExternalPage";
import selectText from "@helpers/selectText";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import CloseIcon from "@material-ui/icons/Close";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import LinkIcon from "@material-ui/icons/Link";
import MenuIcon from "@material-ui/icons/Menu";
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { SetCookieModelOpenContext } from "@pages/_app";
import settings from "@styles/settings";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import DividerTitle from "./helpers/DividerTitle";

const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      overflowX: "hidden",
    },
    li: {
      display: "inline",
      marginLeft: "3px",
      marginRight: "3px",
      fontSize: "16px",
      "&:hover": {
        color: theme.palette.primary.dark,
        cursor: "pointer",
      },
    },
    liMiddle: {
      borderLeft: `1px solid ${theme.palette.primary.light}`,
      // borderRight: `1px solid ${theme.palette.primary.light}`,
      paddingLeft: "3px",
      paddingRight: "3px",
    },
  })
);

interface Props {
  children?: React.ReactChild | React.ReactChild[];
}

export default function Navigation(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();
  const language = router.locale;
  const setCookieModalOpen = useContext(SetCookieModelOpenContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const text = selectText(allText, language as "nl" | "fr" | "en") as PlatformText;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const languageButtons = (
    <div style={{ color: theme.palette.primary.light }}>
      <ul>
        <li className={classes.li}>
          <Link locale="nl" href="">
            <span>NL</span>
          </Link>
        </li>
        <li className={[classes.li, classes.liMiddle].join(" ")}>
          <Link locale="fr" href="">
            <span>FR</span>
          </Link>
        </li>
        {/* <li className={classes.li}>
          <Link locale="en" href="">
            <span>EN</span>
          </Link>
        </li> */}
      </ul>
    </div>
  );

  // --------------------------------------------------------------------------------------
  // ------------------------------------DRAWER--------------------------------------------
  // --------------------------------------------------------------------------------------
  const drawer = (
    <div
      className={classes.drawer}
      role="presentation"
      // onClick={props.toggleDrawer(false)}
      // onKeyDown={props.toggleDrawer(false)}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div>
        <div
          style={{
            height: settings.navBarHeight,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: theme.spacing(2),
          }}
        >
          <Link href="/">
            <a>
              <Image width={100} height={50} src="/images/acerta-logo.svg" />
            </a>
          </Link>
          <Hidden smUp>
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              // className={classes.closeButton}
              edge="start"
            >
              <CloseIcon />
            </IconButton>
          </Hidden>
        </div>

        <List style={{ padding: 0 }}>
          <Divider />
          <DividerTitle title={text.navigation_secondaryActivityDivider} />
          <Link href={text.navigation_bepaalStatuutToolLink} passHref>
            <ListItem button component="a" key={text.navigation_bepaalStatuutToolName}>
              <ListItemIcon>
                <CallSplitIcon />
              </ListItemIcon>
              <ListItemText primary={text.navigation_bepaalStatuutToolName} />
            </ListItem>
          </Link>
          <Link href={text.navigation_berekenBijdrageToolLink} passHref>
            <ListItem button key={text.navigation_berekenBijdrageToolName}>
              <ListItemIcon>
                <EuroSymbolIcon />
              </ListItemIcon>
              <ListItemText primary={text.navigation_berekenBijdrageToolName} />
            </ListItem>
          </Link>
          <DividerTitle title={text.navigation_primaryActivityDivider} />
          <LinkToExternalPage url={text.navigation_nettoPlannerToolLink}>
            <ListItem button key={text.navigation_nettoPlannerToolName}>
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText primary={text.navigation_nettoPlannerToolName} />
            </ListItem>
          </LinkToExternalPage>
          <LinkToExternalPage url={text.navigation_stappenPlanToolLink}>
            <ListItem button key={text.navigation_stappenPlanToolName}>
              <ListItemIcon>
                <EventAvailableIcon />
              </ListItemIcon>
              <ListItemText primary={text.navigation_stappenPlanToolName} />
            </ListItem>
          </LinkToExternalPage>

          <DividerTitle title={text.navigation_otherDivider} />
          <LinkToExternalPage url={text.navigation_FAQLinks}>
            <ListItem button key={text.navigation_FAQ}>
              <ListItemIcon>
                <ImportContactsIcon />
              </ListItemIcon>
              <ListItemText primary={text.navigation_FAQ} />
            </ListItem>
          </LinkToExternalPage>
          <LinkToExternalPage url={"https://www.acerta.be"}>
            <ListItem button key={"Acerta.be"}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary={"Acerta.be"} />
            </ListItem>
          </LinkToExternalPage>
        </List>
      </div>

      <List style={{ padding: 0 }}>
        <ListItem
          button
          key={text.navigation_cookieSettingsLabel}
          onClick={() => {
            console.log("executing setCookieModalOpen");
            setCookieModalOpen(true);
          }}
        >
          <ListItemIcon>
            <SettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={text.navigation_cookieSettingsLabel} />
        </ListItem>
        <LinkToExternalPage url="https://www.aeco.cloud">
          <ListItem button key={"Powered by Aeco"}>
            <ListItemIcon>
              <OfflineBoltOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Powered by Aeco"} />
          </ListItem>
        </LinkToExternalPage>
      </List>
    </div>
  );

  // --------------------------------------------------------------------------------------
  // -----------------------------------APPBAR---------------------------------------------
  // --------------------------------------------------------------------------------------

  const appBar = (
    <AppBar
      position="fixed"
      className={classes.appBar}
      style={{
        background: "#fff",
        boxShadow: "0px 0px 9px 5px rgba(0,0,0,0.17)",
        outlineStyle: "solid",
        outlineColor: theme.palette.grey[300],
        outlineWidth: "1px",
        height: settings.navBarHeight,
      }}
    >
      <Hidden smUp>
        <Toolbar
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            width: "100%",
            height: "100%",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              style={{ color: theme.palette.primary.dark, marginRight: theme.spacing(2) }}
            >
              <MenuIcon />
            </IconButton>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link href="/">
              <a>
                <Image width={100} height={50} src="/images/acerta-logo.svg" />
              </a>
            </Link>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            {languageButtons}
          </div>
        </Toolbar>
      </Hidden>
      <Hidden xsDown>
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div>{languageButtons}</div>
        </Toolbar>
      </Hidden>
    </AppBar>
  );

  // --------------------------------------------------------------------------------------
  // ---------------------------------COMPONENT--------------------------------------------
  // --------------------------------------------------------------------------------------

  return (
    <div style={{ display: "flex" }}>
      {appBar}
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onClick={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main style={{ flexGrow: 1 }}>
        <div style={{ paddingTop: settings.navBarHeight }}>{props.children}</div>
      </main>
    </div>
  );
}
