// import { CookiesChoice, Text } from "../bereken-bijdrage/types";
import allText from "@cms/cookies.json";
import selectText from "@helpers/selectText";
import { Button, Checkbox, Dialog, Divider, Grid, useMediaQuery } from "@material-ui/core/";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useRouter } from "next/router";
import React from "react";
import { useForm } from "react-hook-form";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      border: "none",
      padding: theme.spacing(4, 4, 4),
      outline: "none",
    },
    titel: {
      color: theme.palette.primary.dark,
      fontFamily: "Arial-rounded",
      fontSize: "30px",
      textAlign: "center",
    },
    titel2: {
      color: theme.palette.primary.dark,
      fontFamily: "Arial-rounded",
      fontSize: "24px",
      textAlign: "center",
    },
    section: {
      fontSize: "18px",
      textAlign: "center",
    },
    buttonAcceptAll: {
      padding: "8px 35px",
    },
    buttonAcceptMinimal: {
      // color: theme.palette.primary.main,
      // fontSize: "16px",
    },
    cookieSubTitle: {
      color: theme.palette.primary.light,
      fontSize: "20px",
      fontFamily: "Arial-rounded",
      marginBottom: "5px",
      marginTop: 0,
    },
    cookieRequiredText: {
      color: "gray",
      fontSize: "14px",
      fontStyle: "italic",
    },
    cookieExplenation: {
      fontSize: "16px",
    },
    listContainer: {
      display: "flex",
      flexDirection: "row",
      "& li": {
        listStyleType: "none",
      },
    },
  })
);

const cookies = new Cookies();

type CookiesChoice = {
  functional: boolean;
  recommendation: boolean;
  advertising: boolean;
};

type CookieModalProps = {
  modalOpen: boolean;
  setModalOpen: any;
};

export function CookieModal(props: CookieModalProps) {
  const router = useRouter();
  const language = router.locale;
  const text = selectText(allText, language as "nl" | "fr" | "en");
  const { handleSubmit, register } = useForm();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const onSubmitCookieChoices = async (data: any) => {
    const choices = (data as unknown) as CookiesChoice;
    cookies.set("functional", "true");
    cookies.set("recommendation", choices.recommendation.toString());
    cookies.set("advertising", choices.advertising.toString());
    props.setModalOpen(false);
  };

  const onSubmitAcceptAllCookies = () => {
    cookies.set("functional", "true");
    cookies.set("recommendation", "true");
    cookies.set("advertising", "true");
    props.setModalOpen(false);
  };
  const onSubmitAcceptMinimalCookies = () => {
    cookies.set("functional", "true");
    cookies.set("recommendation", "false");
    cookies.set("advertising", "false");
    props.setModalOpen(false);
  };

  const classes = useStyles();

  return (
    <Dialog
      open={props.modalOpen}
      onClose={() => {
        props.setModalOpen(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      // if no functional cookies are set, you cannot click away the modal
      disableBackdropClick={!cookies.get("functional") ? true : false}
      maxWidth="sm"
      fullScreen={fullScreen}
      fullWidth={true}
    >
      <div className={classes.paper}>
        <h2 className={classes.titel}>{text.cookieModalTitel}</h2>
        <p className={classes.section}>{text.cookieModalSection}</p>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <Button
              className={classes.buttonAcceptAll}
              color="secondary"
              variant="contained"
              onClick={onSubmitAcceptAllCookies}
            >
              {text.cookieModalAcceptAll}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.buttonAcceptMinimal}
              color="primary"
              onClick={onSubmitAcceptMinimalCookies}
            >
              {text.cookieModalAcceptMinimal}
            </Button>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: "40px" }} />
        <h3 className={classes.titel2}>{text.cookieModalTitel2}</h3>
        <form id="cookieForm" onSubmit={handleSubmit(onSubmitCookieChoices)}>
          <div className={classes.listContainer}>
            <div>
              <Checkbox
                style={{ height: "30px" }}
                name="functional"
                color="primary"
                checked={true}
                // disabled={true}
              />
            </div>
            <div>
              <h4 className={classes.cookieSubTitle} style={{ marginBottom: "0px" }}>
                {text.cookieModalFunctionalCookie}*
              </h4>
              <span className={classes.cookieRequiredText}>{text.cookieModalRequired}</span>
              <p className={classes.cookieExplenation}>
                {text.cookieModalFunctionalCookieExplanation}
              </p>
            </div>
          </div>
          <div className={classes.listContainer}>
            <div>
              <Checkbox
                style={{ height: "30px" }}
                inputRef={register()}
                name="recommendation"
                color="primary"
                id="checkboxSuggestion"
              />
            </div>
            <div>
              <label htmlFor="checkboxSuggestion">
                <h4 className={classes.cookieSubTitle}>{text.cookieModalSuggestionCookie}</h4>
                <p className={classes.cookieExplenation}>
                  {text.cookieModalSuggestionCookieExplanation}
                </p>
              </label>
            </div>
          </div>
          <div className={classes.listContainer}>
            <div>
              <Checkbox
                style={{ height: "30px" }}
                inputRef={register()}
                name="advertising"
                color="primary"
                id="checkboxMarketing"
              />
            </div>
            <div>
              <label htmlFor="checkboxMarketing">
                <h4 className={classes.cookieSubTitle}>{text.cookieModalAdvertisementCookie}</h4>
                <p className={classes.cookieExplenation}>
                  {text.cookieModalAdvertisementCookieExplanation}
                </p>
              </label>
            </div>
          </div>

          <Divider style={{ marginTop: "40px", marginBottom: "40px" }} />
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                className={classes.buttonAcceptAll}
                color="secondary"
              >
                {text.cookieModalSaveChoice}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Dialog>
  );
}
