import React, { useState } from "react";
import PropTypes from "prop-types";
import Head from "next/head";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../styles/theme";
import Navigation from "../components/navigation/Navigation";
import { CookieModal } from "@components/footer/CookieModal";
import Cookies from "universal-cookie";
import { useRouter } from "next/router";
import Router from "next/router";
import NProgress from "nprogress"; //nprogress module
import "./../styles/nprogress.css"; //styles of nprogress

// Binding events. => this should display a loading bar
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

export const SetCookieModelOpenContext = React.createContext<any>(undefined);

export default function MyApp(props) {
  const cookies = new Cookies();
  const { Component, pageProps } = props;

  const router = useRouter();
  console.log("language from locale: ", router.locale);

  console.log("functional cookie: ", cookies.get("functional"));
  const [cookieModalOpen, setCookieModalOpen] = useState(!cookies.get("functional") ? true : false);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <React.Fragment>
      <Head>
        <title>Acerta Tools</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <SetCookieModelOpenContext.Provider value={setCookieModalOpen}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Navigation>
            <Component {...pageProps} />
          </Navigation>
          <CookieModal modalOpen={cookieModalOpen} setModalOpen={setCookieModalOpen} />
        </ThemeProvider>
      </SetCookieModelOpenContext.Provider>
    </React.Fragment>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};
