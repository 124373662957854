import {useTheme} from "@material-ui/core"

export default function DividerTitle(props: { title: string }){
  const theme = useTheme()
  return (
    <div>
      {/* <Divider/> */}
      <div
        style={{
          backgroundColor: theme.palette.grey[0],
          height: "50px",
          display: "flex",
          alignItems: "center",
          paddingLeft: theme.spacing(2),
          fontFamily: "Arial-rounded",
          color: theme.palette.primary.dark,
        }}
      >
        {props.title}
      </div>
      {/* <Divider/> */}
    </div>
  );
};